import React, { useState, useEffect } from 'react'
import { Text, TileWrapper, Button, View, Input } from 'lib'
import { withStyles } from '@material-ui/styles'
import { Grid, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { navigate, Link } from 'gatsby'
import firebase from 'gatsby-plugin-firebase'
import emailValidator from 'email-validator'
import { TwoFADialog } from './profile/_dialogs'

import theme from '_appSetup/Theme'

import { fetchUserData } from 'actions/User'
import { initAppsFlyer, trackAppsFlyerEvent } from 'utils/analytics'

const Login = (props) => {
  const [email, setEmail] = useState(props.location?.state?.initialEmail || '')
  const [emailReset, setEmailReset] = useState(props.location?.state?.initialEmail || '')
  const [password, setPassword] = useState(props.location?.state?.initialPassword || '')
  const [loading, setLoading] = useState(false)
  const [errorBarOpen, setErrorBarOpen] = useState(false)
  const [emailNotVerified, setEmailNotVerified] = useState(false)
  const [verificationLinkResent, setVerificationLinkResent] = useState(false)
  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState(false)
  const [resetPasswordLinkSent, setResetPasswordLinkSent] = useState(false)
  const [twoFADialogOpen, setTwoFADialogOpen] = useState(false)
  const [twoFADialogData, setTwoFADialogData] = useState(false)

  const checkAuthRequirements = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      // Just to check if email/password are correct. Then, log out immediately in case 2FA fails.
      await firebase.auth().signInWithEmailAndPassword(email, password)

      const authStatusFunc = firebase.functions().httpsCallable('getUserAuthStatus')
      const { data } = await authStatusFunc()

      if (!data.emailVerified) {
        setEmailNotVerified(true)
        setLoading(false)
      } else if (data['2FASecret']) {
        await firebase.auth().signOut()
        setTwoFADialogData({ email, uid: data.uid, '2FASecret': data['2FASecret'] })
        setTwoFADialogOpen(true)
        setLoading(false)
      } else {
        attemptSignin({ authNeeded: false })
      }

    } catch (e) {
      log(e)
      if (['auth/user-not-found', 'auth/wrong-password'].includes(e.code)) {
        setErrorBarOpen(true)
        setLoading(false)
      }
    }

  }

  const attemptSignin = async ({ authNeeded = true }) => {
    try {
      if (authNeeded) await firebase.auth().signInWithEmailAndPassword(email, password)

      if (window.analytics) window.analytics.identify(email)

      const UserData = await fetchUserData({})

      initAppsFlyer({
        email: UserData.email,
        successCallback: () => {
          trackAppsFlyerEvent({ eventName: 'af_login' })
        },
      })

      const successDestination = UserData?.accountClosed ? '/closed' : '/dashboard'

      navigate(successDestination)

    } catch (e) {
      log(e)
      if (['auth/user-not-found', 'auth/wrong-password'].includes(e.code)) {
        setErrorBarOpen(true)
      }
    }
    setLoading(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setErrorBarOpen(false)
  }

  const inputValid = emailValidator.validate(email) && password.length > 0

  const resendVerificationLink = () => {
    const user = firebase.auth().currentUser
    if (user && !verificationLinkResent) {
      user.sendEmailVerification()
      setVerificationLinkResent(true)
    }
  }

  const sendPasswordResetLink = () => {
    setResetPasswordLinkSent(true)
    firebase.auth().sendPasswordResetEmail(emailReset)
  }

  const closeVerificationModal = () => {
    setEmailNotVerified(false)
    setTimeout(() => {
      setVerificationLinkResent(false)
    }, 500)
  }

  const emailResetValid = emailValidator.validate(emailReset)

  const styles = props.classes
  return (
    <TileWrapper large>
      <Grid item xs={12}>
        <Text variant={'h6'} text={'Sign in to Stablegains'} align='center'/>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <form style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} onSubmit={checkAuthRequirements}>
          <Input
            value={email}
            onChange={evt => setEmail(evt.target.value)}
            type='email'
            name='email'
            label={'Email address'}
            fullWidth
            autoFocus
            size='small'
          />
          <Input
            value={password}
            onChange={evt => setPassword(evt.target.value)}
            type='password'
            name='password'
            label={'Password'}
            fullWidth
            size='small'
          />
          <Button disabled={!inputValid || loading} loading={loading} style={styles.submitButton} variant='contained' size='large' color='secondary' text={'SIGN IN'} type='submit' fullWidth/>

          {/* <Text gutterBottom variant={'subtitle2'} align='center'>Don't have an account yet? <Link to={'/signup'}>Sign up</Link></Text> */}
          <Text variant={'subtitle2'} align='center'><Link onClick={() => setForgotPasswordDialogOpen(true)} to='#'>Forgot your password?</Link></Text>
        </form>
      </Grid>
      <Snackbar open={errorBarOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='error'>
          Incorrect email or password.
        </Alert>
      </Snackbar>

      <Dialog
        open={emailNotVerified}
        onClose={() => setEmailNotVerified(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Email not verified'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
      Please check your e-mail for the verification link we sent you. It might be in your spam/junk folder.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resendVerificationLink} color='primary'>
            {verificationLinkResent ? 'Sent!' : 'Resend verification link'}
          </Button>
          <Button onClick={closeVerificationModal} color='primary' autoFocus>
      Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={forgotPasswordDialogOpen}
        onClose={() => setForgotPasswordDialogOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Reset password'}</DialogTitle>
        <DialogContent>

          <DialogContentText id='alert-dialog-description'>
            What is the email address associated with your account?
          </DialogContentText>

          <Input
            value={emailReset}
            onChange={evt => setEmailReset(evt.target.value)}
            type='email'
            name='email'
            label={'Email address'}
            fullWidth
            size='small'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={sendPasswordResetLink} color='primary' disabled={!emailResetValid || resetPasswordLinkSent}>
            {resetPasswordLinkSent ? 'Sent!' : 'Reset password'}
          </Button>
          <Button onClick={() => setForgotPasswordDialogOpen(false)} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <TwoFADialog
        open={twoFADialogOpen}
        twoFADialogData={twoFADialogData}
        closeDialog={() => setTwoFADialogOpen(false)}
        onConfirm={() => {
          setLoading(true)
          attemptSignin({ authNeeded: true })
          setTwoFADialogOpen(false)
        }}
      />
    </TileWrapper>
  )
}

const styles = {
  message: {
    fontSize: 18,
    color: 'rgba(0, 0, 0, .5)',
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
  },
  inputWrapper: {
    flexDirection: 'column',
    ...theme.center,
  },
  pageWrapper: {
    backgroundColor: '#D9EBF3',
    ...theme.center,
    ...theme.absolute,
  },
  logo: {
    width: '100%',
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  circularWrapper: {
    marginTop: theme.spacing(2),
    alignSelf: 'center',
  },
}

export default withStyles(styles)(Login)
